.membership-plan-container {
  /* width: 80vw; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 10px auto 30px auto;
}

.membershipPageHeading {
  text-align: center;
  font-size: 3rem;
  padding-top: 30px;
}

.planSelectors {
  width: 60vw;
  margin: auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.singleSelector {
  margin: 20px 8px;
}

.sessionSelection {
  border: 1.6px solid #17a3fd;
  width: 600px;
  padding: 20px 30px;
  /* border-radius: 50px; */
}

#dialogBox {
  border-radius: 10px !important;
}

.dialogActionButtons {
  display: flex;
  justify-content: space-between !important;
}

.dialogActionButton {
  height: 50px !important;
  width: 180px !important;
  background-color: #17a3fd !important;
  color: white !important;
  border-radius: 5px !important;
  border: none;
}

.plansDisplay {
  /* width: 60vw; */
  margin: auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.singleSelector__button {
  width: 10rem !important;
  height: 55px !important;
  border-radius: 10px !important;
  background-color: #c9c9c9 !important;
  border: none !important;
  color: #808080 !important;
  font-size: 1rem;
  padding: 0 0.23em !important;
}

.singleSelector__button.active {
  background-color: #17a3fd !important;
  color: white !important;
}

.singleSelector__button:hover {
  background-color: #17a3fd !important;
  color: white !important;
}

.confirmPackageContainer {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.continueToPayment {
  background-color: #17a3fd !important;
  color: white !important;
  border: none !important;
  width: 200px;
  height: 50px;
  margin: auto;
  margin-bottom: 80px;
  border-radius: 5px !important;
}
.tab__containerWrapper {
  display: initial;
  max-width: 80%;
}
.tab__container,
.tab__containerMobile {
  display: flex;
  gap: 1rem;
  position: relative;
  margin: 0 auto;
  margin-top: 2rem;
  justify-content: center;
  max-width: 90%;
}
.tab__container {
  display: none;
}
.membership__leftIcon,
.membership__rightIcon {
  cursor: pointer;
  border: 1px solid rgb(231, 231, 231);
  border-radius: 50%;
  background-color: rgb(255, 255, 251);
  color: rgb(138, 138, 138);
}
.tab__containerWrapper {
  display: flex;
  margin: 0 auto;
  justify-content: space-evenly;
  align-items: center;
}
@media screen and (min-width: 900px) {
  .tab__container {
    display: flex;
    flex-wrap: wrap;
  }
  .membership__leftIcon,
  .membership__rightIcon {
    display: none !important;
  }
  .tab__containerMobile {
    display: none;
  }
}

.tab__container::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, -20px);
  width: calc(105%);
  height: 2px;
  background: #1f84fc;
}
.tab__wrapper {
  width: max(200px, 5vw);
  padding: 1rem;
  position: relative;
  margin-bottom: 20px;
  text-align: center;
  background-color: #e2e2e2;
  color: #064065;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.tab__wrapper.active {
  background-color: #1f84fc;
  color: #fff;
}
.tab__wrapper.active::after {
  content: "";
  position: absolute;
  left: 50%;
  transform: translate(-50%, 15px);
  bottom: 0;
  width: 0;
  height: 0;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  border-top: 15px solid #1f84fc;
}

.tab__wrapper p {
  font-size: 1.5rem;
  white-space: nowrap;
}

.membership__point {
  display: flex;
  margin: 0.2rem;
  justify-content: flex-start;
  align-items: center;
}
.membership__point p {
  font-size: clamp(0.5rem, 6vw, 1.875rem);
}

.membership__point img {
  width: 2.5rem;
  height: 1.875rem;
}

.membership__card {
  width: 278px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding-top: 1rem;
  box-sizing: border-box;
  position: relative;
}

.membership__card h1 {
  color: #06b100;
  font-weight: 900;
  font-size: 4.125rem;
  margin-bottom: 0;
  padding-bottom: 0;
  display: inline-block;
}

.membership__card h2 {
  margin-top: 0;
  margin-bottom: 5px;
  padding-top: 0;
  font-size: 1.125rem !important;
}

.membership__btn {
  text-transform: uppercase;
  background: #064065;
  color: #fff;
  width: 100%;
  padding: 0.9em 0;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}
.membership__btn:hover {
  background: #1f84fc;
}
.membership__btn img {
  height: 20px;
  width: 20px;
  margin-left: 5px;
}

.membership__linethrough {
  position: relative;
  color: hsl(0, 0%, 30%);
}
.membership__linethrough::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background: #1f84fc;
  transform: translateY(10px) rotateZ(30deg);
}
.btn-default {
  background: #1f84fc;
}
.membership__cardContainer {
  width: 80%;
  margin: 1rem auto;
  display: flex;
  gap: 2rem;
  justify-content: center;
  flex-wrap: wrap;
}

.fitness__checkbox {
  position: relative;
  min-width: 80px;
  height: 40px;
  margin: 10px;
  outline: none;
  background: #f1f1f1;
  -webkit-appearance: none;
  cursor: pointer;
  border-radius: 20px;
  border: 3px solid #1f84fc;
  transition: 0.5s;
  margin: 3em 1em;
}

.fitness__checkbox:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 35px;
  height: 35px;
  background: #1f84fc;
  border-radius: 20px;
  box-shadow: 0 0 0 1px #f1f1f1;
  transform: scale(0.98, 0.96);
  transition: 0.5s;
}
.fitness__checkbox:checked::before {
  /* left: 40px; */
  left: 100%;
  transform: translateX(-100%);
}

.fitness__checkboxCon {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 80%;
  margin: 0 auto;
}

.fitness__checkboxCon h3 {
  color: #064065;
  margin: 0;
}
