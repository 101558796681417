.checkoutContainerBox {
  width: 60vw;
  height: 350px;
  display: flex;
  border: 1px solid black;
  margin: 150px auto 50px auto;
  padding: 15px 30px;
  flex-direction: column;
}

.headerTextDiv__heading {
  font-size: 1.7rem;
}

.bodyTable {
  height: 40px;
  display: flex;
  flex-direction: row;
  width: 100%;
  /* font-weight: bold;
  font-size: 1.2rem; */
}

.bodyTable {
  font-weight: bold;
  font-size: 1.2rem;
}

.bodyLeft {
  width: 20%;
}
.bodyCenter {
  width: 60%;
}
.bodyRight {
  width: 20%;
}

.bodyTableHead {
  margin-top: 2%;
}
.bodyTableContent {
  margin-top: 5%;
}

.bodyTablePrice {
  margin-top: 8%;
}

.Totalprice {
  border-top: 1px solid black;
}
