@import url(https://cdnjs.cloudflare.com/ajax/libs/semantic-ui/2.2.12/semantic.min.css);
.nf {
  margin: auto;
  width: 10%;
  padding: 10px;
  font-size: 100px;
}

.nft {
  margin-left: 35%;
}

.nfm {
  margin-left: 37%;
}

.nfb {
  margin-left: 47%;
  margin-top: 10%;
}

.modal-backdrop {
  z-index: 2000;
}

.modal-dialog {
  z-index: 2010;
}

.ui.modal > .close {
  top: 1rem;
  right: 0.5rem;
  color: #333;
}

.learn {
  border: 1px solid #07f;
  border-radius: 20px;
  margin-top: 1em;
  padding: 0.3em 1em;
  color: #07f;
  transition-duration: 0.3s;
}

.learn:hover {
  background-color: violet;
  border: 1px solid violet;
  color: #fff;
}

.offer-card {
  width: 20rem;
  border: 1px solid #eee;
  border-radius: 30px;
  overflow: hidden;
  margin: 1em;
  position: relative;
}
.offer-card img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  padding: 0 !important;
  margin: 0 !important;
}
.offer-card h1 {
  background-color: rgba(238, 238, 238, 0.9);
  padding: 5px 0;
  font-size: 1.5rem;
  position: absolute;
  bottom: 0;
  left: 0;
  margin: 0 auto;
  width: 100%;
}
#services,
#testimonials {
  width: min(90vw, 80rem);
  margin: 0 auto;
}
.ui.fullscreen.modal {
  width: 95% !important;
  left: 2.5% !important;
  margin: 1em auto;
  height: 100%;
  overflow: scroll;
}

.work-container {
  width: min(90vw, 70rem);
  margin: 2em auto;
  border-radius: 20px;
  box-shadow: 0 1.5px 10px rgba(0, 0, 0, 0.07), 0 1px 10px rgba(0, 0, 0, 0.14);
  padding: 3em 0;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
}
.work-container > * {
  flex-basis: 1;
}
.work-container div {
  margin: 10px 0;
  max-width: 15rem;
}

.card-number {
  color: #5da0e7;
  font-size: 5.5rem;
}

.process-card-title {
  font-size: 3.5em;
  margin-top: -20px;
  max-width: 45ch;
}

.process-con {
  display: flex;
  flex-direction: row;
  margin-top: 3em;
}
.process-con > * + * {
  margin-left: 5em;
}
.process-con p {
  color: #193144;
  max-width: 30ch;
  font-size: 1.5em;
  color: "#0b0818";
}
.process-con > * {
  flex-basis: 1;
}

.text-r {
  text-align: right;
}

.page_404{ padding:40px 0; background:#fff; font-family: 'Arvo', serif;
}

.page_404  img{ width:100%;}

.four_zero_four_bg{
 
 background-image: url(https://cdn.dribbble.com/users/285475/screenshots/2083086/dribbble_1.gif);
    height: 400px;
    background-position: center;
 }
 
 
 .four_zero_four_bg h1{
 font-size:80px;
 }
 
  .four_zero_four_bg h3{
			 font-size:80px;
			 }
			 
			 .link_404{			 
	color: #fff!important;
    padding: 10px 20px;
    background: #1f84fc;
    margin: 20px 0;
    display: inline-block;}
	.contant_box_404{ margin-top:-50px;}

@media only screen and (max-width: 767px) {
  .ui.stackable.cards .card:first-child {
    margin-top: 2em !important;
  }
  .ui.stackable.cards > .card {
    display: block !important;
    height: auto !important;
    margin: 1em;
    padding: 1em !important;
    width: calc(100% - 2em) !important;
  }
}

@media only screen and (max-width: 750px) {
  .process-con {
    display: flex;
    flex-direction: column;
    text-align: left !important;
  }
  .process-con > * + * {
    margin-left: 0;
  }
  .text-r {
    text-align: left !important;
  }
}
