.Weight-Div{
    display:flex;
    flex-direction:column;
}

.Weight-Main{
    display:flex;
    flex-direction:row;
    justify-content: space-evenly;
    align-items: center;
    min-height:200px;
}
.Weight-Main div{
    width:50%;
}

.Weight-Main-Div > div{
    width:90%;
    margin:0 auto;
    display:flex;
    flex-direction: column;
    justify-content: space-between;
}
.buttcss{
    font-size: 1.2rem;
    width:70%;
}
.buttcss2{
    font-size: 0.9rem;
    width:70%;
}
img{
    width:80%;
    height:auto;
}
.Decsribe-Main{
    padding:20px 0;
    background:rgba(211, 211, 211, 0.2);
    display:flex;
    flex-direction: column;
    align-items: center;
    margin-top:20px;
}

ul{
    color: dodgerblue;
}
li{
    margin:5px 0;
    padding-left: 0;
}
li span{
    color:black;
}
.ImageDescribe-Div{
    display:flex;
    flex-direction: column;
    align-items: center;
    padding: 20px 0;
}
.ImageDescribe-Main{
    display: flex;
    flex-direction:row;
    width:70%;
    margin:0 auto;
    justify-content: space-evenly;
}
.ImageDescribe-Main-Div{
    display: flex;
    flex-direction: column;
    align-items: center;
    width:100%;
    background:rgba(211, 211, 211, 0.2);
    margin:0 10px;
    justify-content: space-evenly;
    padding-bottom:10px;
}
.ImageDescribe-Main-Div > div{
    width:90%;
}
.ImageDescribe-Main-Div > div > img{
    width:100%;
    min-width: 100px;
    height:auto;
}
.PriceDetail-Main{
    width:30%;
    margin:0 auto;
    padding:20px;
    display:flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    background: rgba(211, 211, 211, 0.2);
    text-align: center;
}
.PriceDetail-Main-Div{
    width:100px;
    height:100px;
    border: 5px solid black;
    border-bottom-color: dodgerblue;
    color:dodgerblue;
    border-radius: 50%;
    
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.Help-Main{
    margin-top:20px;
    padding:20px 0;
    background: rgba(211, 211, 211, 0.2);
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
}
.Help-Form{
    width:50%;
}
.Help-Form input{
    width:100%;
    border-radius: 20px 20px;
    padding: 10px;
    background:lightcyan;
    border-top-color:lightblue;
    color:silver;
    border-color: dodgerblue !important;
}
.Logo-Main{
    margin-top:10px;
    display:flex;
    flex-direction:row;
    justify-content: center;
    align-items: center;
    padding:10px;
}
.Logo-Main > div{
    width:30px;
    height:30px;
    border-radius: 50%;
    background:rgba(211,211,211,0.2);
    margin-right:10px;
}
.Logo-Main > div > img {
    width:70%;
    position: relative;
    left:5px;
    top:4px;
    height:auto;
    
}
.Contact-Main{
    width:70%;
    margin: 0 auto;
    margin-top: 10px;
    padding:10px 0;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}
.Contact-Main-Div{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.Contact-Main-Div div,
.Contact-Main-Div div>img {
    width:50%;
    height:auto;
    margin:0 auto;
    
}
.Contact-Main-Div div>img{
    position: relative;
    left:2.5vw;
}
@media(max-width:768px){
    .buttcss,.buttcss2{
        width:90%;
        font-size: 0.8rem;
    }
    h2{
        font-size:3vw !important;
    }
}
@media(max-width:580px){
    .PriceDetail-Main{
        width:90%;
    }
    .buttcss,.buttcss2{
        width:100%;
        font-size: 0.7em;
    }
    .Help-Form{
        width:80%;
    }
}