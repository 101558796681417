@import url(https://cdnjs.cloudflare.com/ajax/libs/semantic-ui/2.2.12/semantic.min.css);
@import url(https://fonts.googleapis.com/css?family=Montserrat:600&display=swap);
@import url(https://use.fontawesome.com/releases/v5.8.1/css/all.css);
@import url(https://stackpath.bootstrapcdn.com/bootstrap/4.1.2/css/bootstrap.min.css);
@import url(https://fonts.googleapis.com/css?family=Montserrat:300,400,600);
@import url(https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700&display=swap);
@import url(https://cdnjs.cloudflare.com/ajax/libs/semantic-ui/2.2.12/semantic.min.css);
/* @import url(style.min.css); */
.no-padding,
body {
  padding: 0;
}
.h-font-wt {
  font-weight: 600;
}
.web-wrapper {
  width: 100%;
  padding: 0;
}
.nav-item button {
  margin-right: 20px;
}
.classic-tabs li a.active {
  border-bottom: 3px solid;
  border-color: #104c8b;
}
.error {
  color: red;
}
.gradUp {
  background: #1279b7;
  background: linear-gradient(#1279b7, #1eb9a7);
}
.light-grey-text {
  color: #f0f0f0;
}
.deep-purple-text {
  color: #7c4dff;
}
.deep-blue-bg {
  background-color: #161d26;
}
.light-grey-bg {
  background-color: #f0f0f0;
}
.bronze-gradient {
  background: linear-gradient(to bottom, #cd7f32 0, #be7023 100%);
  background: -ms-linear-gradient(top, #cd7f32 0, #be7023 100%);
}
.aqua-blue {
  background-color: #52e89e;
}
.silver-gradient {
  background: silver;
  background: linear-gradient(to bottom, silver 0, #b1b1b1 100%);
  background: -ms-linear-gradient(top, silver 0, #b1b1b1 100%);
}
.gold-gradient {
  background: #d4af37;
  background: linear-gradient(to bottom, #d4af37 0, #c5a028 100%);
  background: -ms-linear-gradient(top, #d4af37 0, #c5a028 100%);
}
.baby-powder-bg {
  background: #fefefa;
  background: linear-gradient(to bottom, #fefefa 0, #efefeb 100%);
  background: -ms-linear-gradient(top, #fefefa 0, #efefeb 100%);
}
#why-deevefit {
  background-color: #fff;
  padding: 10px 20px;
}
.figure h4 {
  color: #006738;
  padding: 10px 0 5px;
}
#our-features {
  background: linear-gradient(to top right, #29f498 0, #0015d1 100%);
  padding: 0 0 45px;
}
.feature-white-box {
  padding: 20px 0;
  background-color: #fff;
  border-radius: 25px;
}
.media {
  margin: auto;
  padding: 5px;
}
.media-body h5 {
  color: #00aeef;
}
.f-image {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media only screen and (max-width: 577px) {
  .fl-header,
  .fr-header {
    text-align: center !important;
  }
  .fl-para,
  .fr-para {
    font-size: 18px;
  }
  .fl-header {
    margin-top: 20px;
  }
}
#socialicons,
#socialicons .icon,
.footer-copyright,
.Membership-footer,
.section-content {
  text-align: center;
}
@media only screen and (min-width: 577px) and (max-width: 767px) {
  .fl-header,
  .fr-header {
    font-size: 2em;
  }
}
@media only screen and (min-width: 768px) {
  .fl-para,
  .fr-para {
    font-size: 25px;
  }
}
#how-it-works {
  padding-bottom: 30px;
  background-color: #f0f0f0;
}
.thumbnail-box {
  padding: 0 !important;
  margin: 0;
  border: 0;
}
.thumbnail img {
  border: 0;
  height: 100%;
  width: 100%;
}
.thumbnail img:after,
.thumbnail img:hover {
  box-shadow: 5px 5px 5px #aaa;
  -webkit-transform: scale(1.1, 1.1);
          transform: scale(1.1, 1.1);
}
.content-header {
  font-family: "Oleo Script", cursive;
  color: #fcc500;
  font-size: 45px;
}
#contact {
  font-family: Quattrocento, sans-serif;
  padding-top: 60px;
  width: 100% !important;
  height: 480px;
  max-width: 100%;
  background: #3a6186;
  background: linear-gradient(to left, #1279b7, #1eb9a7);
  color: #fff;
}
.contact-section {
  padding-top: 40px;
}
.contact-section .col-md-6 {
  width: 50%;
}
.form-line {
  border-right: 1px solid #52e89e;
}
.form-group {
  margin-top: 10px;
}
.form-group label {
  font-size: 1.3em;
  line-height: 1em;
  font-weight: 400;
}
.form-control {
  font-size: 1.3em;
  color: #080808;
}
textarea.form-control {
  height: 135px;
}
input[type="text"] {
  position: relative;
  display: block;
  margin: 0 auto !important;
}
.Membership-tab-wrapper {
  padding: 0;
}
.Membership-tab-top .price {
  padding: 0 5px 5px 15px;
}
.Membership-tab-top .price .org-price {
  font-weight: 700;
  font-size: 30px;
  margin: auto;
}
.Membership-tab-top .price .discounted-price {
  font-weight: 700;
  font-size: 50px;
  margin: auto;
}
.price small {
  font-size: 10px;
  font-weight: 400;
}
.Membership-tab-bottom {
  padding-bottom: 10px;
  margin-bottom: 40px;
}
.Membership-tab-bottom ul {
  list-style: none;
  margin: auto;
  text-align: center;
  padding-left: 0;
  padding-bottom: 15px;
}
.plan-type {
  padding: 5px 7px;
  font-weight: 500;
  text-transform: uppercase;
  font-size: 30px;
  color: #fff;
}
.Membership-tab-bottom ul .li-default {
  padding: 10px 10px 0;
  cursor: pointer;
  font-weight: 400;
  font-size: 20px;
}
.perclass-price {
  cursor: pointer;
  font-weight: 400;
  font-size: 30px;
}
.Membership-footer p {
  padding: 10px 10px 0;
  font-size: 10px;
}
.Membership-footer {
  width: 100%;
  margin: auto;
  align-items: center;
  justify-content: center;
}
.strikethrough {
  position: relative;
}
.strikethrough:before {
  position: absolute;
  content: "";
  left: 0;
  top: 50%;
  right: 0;
  border-top: 2px solid;
  border-color: inherit;
  color: #ef5350;
  -webkit-transform: rotate(-5deg);
  transform: rotate(-5deg);
}
#socialicons .icon {
  background: #000;
  display: inline-block;
  color: #fff;
  font-size: 18px;
  width: 34px;
  height: 34px;
  line-height: 34px;
  vertical-align: middle;
  margin: 0 1px 8px;
  border-radius: 17px;
}
#socialicons .icon:hover {
  background: #7c4dff;
}
.footer-copyright {
  background-color: #1d1f25;
  color: #fff;
  overflow: hidden;
  height: 50px;
  font-size: 0.9rem;
  line-height: 50px;
}
.footer-copyright a {
  color: rgba(255, 255, 255, 0.6);
}
.page-footer a,
.white-text {
  color: #fff;
}
.deep-purple.accent-2 {
  background-color: #7c4dff !important;
}
.footer-social a {
  padding: 10px;
}
.page-footer {
  background-color: #26282e;
  color: #fff;
}
@media only screen and (max-width: 767px) and (min-width: 576px) {
  .form-group label {
    font-size: 1.2em;
  }
  input::-webkit-input-placeholder {
    font-size: 0.8em;
  }
  input:-ms-input-placeholder {
    font-size: 0.8em;
  }
  input::placeholder {
    font-size: 0.8em;
  }
  .plan-type {
    font-size: 1.5em;
  }
  .Membership-tab-top .price .discounted-price {
    font-size: 3em;
  }
  .resposive-btn {
    width: 157px;
    font-size: 0.8em !important;
    text-align: center;
    padding: 5px;
  }
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
#goal {
  background: #f4f4f4;
}

#footer a:link,
#footer li,
#footer p {
  color: #000;
  font-size: 1.25rem;
  font-weight: 500;
}

h1,
h2,
p {
  font-family: "Roboto", sans-serif;
}
.hd {
  font-family: "Bebas Neue", cursive;
  padding: 0;
  margin: 0;
  color: #074266;
}
a:hover,
button {
  cursor: pointer;
}

#footer,
#goal,
#howitworks,
#join,
#Membership,
#why {
  text-align: center;
}

.benifitscard,
.headinghero {
  box-sizing: border-box;
  overflow: hidden;
}

#hero,
.benifitscard,
.headinghero,
.inputfile {
  overflow: hidden;
}

#goal {
  padding: 7vh 0;
  font-size: 16px;
}

#why {
  background: #fff;
  padding: 7vh 3em;
  height: -webkit-min-content;
  height: min-content;
}

#howitworks,
#join {
  padding: 10vh 0;
}

/* #features img {
  width: 13vw;
  padding-right: 2em;
} */

#footer {
  /* padding: 6vh 0 0; */
  background: #ededed;
}

#footer li {
  list-style-type: none;
  padding-top: 10px;
}

#login-button {
  font-size: 17px;
  background-color: #0bf;
  color: #fff;
  margin-top: 0.75em;
  margin-right: 2.5em;
  display: inline-block;
  padding-left: 3em;
  padding-right: 3em;
  transition-duration: 0.3s;
}

#login-button:hover {
  background-color: #acf;
  color: #333;
}

#useremail,
#username,
#userpassword {
  border: 1px solid #0cf;
  width: -webkit-fill-available;
  height: 40px;
  border-radius: 6px;
  outline: 0;
  padding: 15px;
}

#useremail:focus,
#username:focus,
#userpassword:focus {
  border: 1px solid #06b;
}

.ui-timepicker-container.ui-timepicker-standard {
  z-index: 100000 !important;
}

h1 {
  font-weight: 600;
}

.collapse {
  font-family: Montserrat;
  font-weight: 400;
  font-size: 16px;
  align-items: right;
  margin-top: 5px !important;
  margin-bottom: 0 !important;
}

.paragraph {
  color: #555;
  font-weight: 300;
}

.paragraph:hover {
  color: #000;
}

.input-group-addon {
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}

.clsDatePicker {
  z-index: 100000;
}

.ad::before {
  display: block;
  content: " ";
  height: 20px;
  margin-top: 20px;
  visibility: hidden;
}

.heading {
  color: #000;
  font-size: 35px;
  font-weight: 600;
}

.number {
  font-size: 60px;
  color: #4040dd;
}

.headingcard {
  font-size: 28px;
}

.card:hover {
  box-shadow: 0 0 24px rgba(0, 0, 0, 0.3);
  -webkit-transform: scale(1.05, 1.05);
          transform: scale(1.05, 1.05);
  z-index: 1000;
  transition: 0.3s;
}

.signup {
  padding: 15px 50px;
  font-weight: 300;
  font-size: 24px;
  color: #fff;
  border-radius: 40px;
  background: #4040dd;
  transition-duration: 0.5s;
}
.span-pos {
  color: #3c506f;
  font-weight: 600;
}

.cool-link::after,
.cool-link:hover::after {
  transition: width 0.3s;
  text-decoration: none;
}

.signup:hover {
  background: 0 0;
  border: 1px solid #04040d;
  color: #000;
}

.heading1 {
  font-size: 25px;
}

.cool-link {
  display: inline-block;
  color: #000;
  text-decoration: none;
}

.cool-link::after {
  content: "";
  display: block;
  width: 0;
  padding: 0;
  height: 2px;
  background: #000;
}

.cool-link:hover::after {
  width: 100%;
}

a[class*="cool-link"]:hover {
  text-decoration: none;
  color: #000;
}

.ui.dropdown .menu {
  left: -1.25em;
}

@media only screen and (min-width: 992px) {
  .activ {
    /* border-top: solid 5px #4040dd; */
    border-bottom: solid 5px #00b1ff;
    /* margin-top: -5px */
    color: #00b1ff;
  }
  .activ .nav-link {
    color: #00b1ff !important;
  }
  li {
    transition-duration: 0.2s;
    margin-left: 1px;
  }
  .nav-item:not([className*="activ"]):hover {
    /* border-top: solid 5px #00B1FF; */
    border-bottom: solid 5px #00b1ff;
    /* margin-top: -5px!important; */
    /* margin-bottom: 0!important */
  }
}

button:focus {
  outline: 0 !important;
}

button:not([class*="react-"]):not([class*="learn"]):not([class*="ui"]):not([class*="timezone-"]):not([class*="hero-"]) {
  border-radius: 30px;
  padding: 0.4em 1.6em;
  margin-top: -0.3em;
  background-color: #4040dd;
  color: #fff;
  transition-duration: 0.3s;
  outline: 0;
}

button:not([class*="react-"]):not([class*="learn"]):not([class*="ui"]):not([class*="timezone-"]):not([class*="hero-"]):hover {
  border-radius: 20px;
  background-color: #fff;
  color: #4040dd;
  border: 1px solid #00b1ff;
}

#hero {
  /* height: 80vh; */
  max-height: 87vh;
  /* background-image: url(/assets/images/cover-desk.svg);
	background-repeat: no-repeat;
	background-position: right;
	background-size: contain;
	padding: 33vh 0;
	margin-top: 1em; */
}

.headinghero {
  color: "#3c506f" !important;
  font-size: 1.3rem;
}
.a-help {
  display: flex;
  flex-direction: column;
  width: -webkit-max-content;
  width: max-content;
}

@media only screen and (min-width: 601px) and (max-width: 767px) {
  /* #features img {
    width: 40vw;
    margin-left: 20vw;
  } */
  #why {
    padding: 7vh 2em !important;
  }
}

/* * {
	transition: .3s
} */

@media only screen and (max-width: 720px) {
  #hero {
    /* height: 80vh; */
    /* height: 450px; */
    /* background-image: url(/assets/images/cover-mob.svg);
		background-size: contain!important;
		background-position: left top!important;
		width: 100%;
		background-repeat: no-repeat;
		padding-top: 40vh;
		overflow: hidden */
  }
  .hero1 {
    text-align: center;
  }
  .a-help {
    align-items: center;
    width: auto;
  }
  .headinghero {
    color: #000;
    font-size: 1.3rem;
    box-sizing: border-box;
    overflow: hidden;
  }
  #mobile-menu-close a,
  #mobile-menu-open a {
    padding: 8px 8px 8px 5px;
    text-decoration: none;
    font-size: 20px;
    color: #111;
    display: block;
    transition: 0.3s;
  }
  #features img {
    width: 40vw;
  }
  li {
    padding-left: 2.5em;
  }
  .activ {
    background-color: #eee;
  }
  #mobile-menu-close,
  #mobile-menu-open {
    height: 100%;
    position: fixed;
    z-index: 1005;
    top: 0;
    left: 0;
    background-color: #fff;
    overflow-x: hidden;
    padding-top: 15px;
  }
  #features {
    text-align: center;
  }
  #why {
    padding: 7vh 2em;
  }
  #desktop-menu {
    display: none;
  }
  #toggle-mobile-menu {
    display: inline-block !important;
    padding-top: 1em;
    cursor: pointer;
  }
  #mobile-menu-close {
    width: 0;
    -webkit-animation-name: close_menu;
    -webkit-animation-duration: 0.3s;
    animation-name: close_menu;
    animation-duration: 0.3s;
  }
  @-webkit-keyframes close_menu {
    from {
      width: 15em;
    }
    to {
      width: 0;
    }
  }
  @keyframes close_menu {
    from {
      width: 15em;
    }
    to {
      width: 0;
    }
  }
  #mobile-menu-open {
    width: 15em;
    box-shadow: 3px 0 7px rgba(0, 0, 0, 0.4);
    -webkit-animation-name: open_menu;
    -webkit-animation-duration: 0.3s;
    animation-name: open_menu;
    animation-duration: 0.3s;
  }
  @-webkit-keyframes open_menu {
    from {
      width: 0;
    }
    to {
      width: 15em;
    }
  }
  @keyframes open_menu {
    from {
      width: 0;
    }
    to {
      width: 15em;
    }
  }
  #mobile-menu-open a {
    position: relative;
  }
  #mobile-menu-open a:hover {
    background-color: #ddd;
  }
}

@media only screen and (min-width: 720px) {
  #mobile-menu-close,
  #mobile-menu-open,
  #toggle-mobile-menu {
    display: none;
    height: 0;
  }
  #mobile-menu-close a,
  #mobile-menu-open a {
    display: none;
  }
}

.inputfile {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  position: absolute;
  z-index: -1;
}

.inputfile + label {
  width: 5em;
  /* height: 5em; */
  display: inline-block;
  cursor: pointer;
}

.navbar {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
}

@media only screen and (max-width: 991px) {
  .navbar {
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
  }
}

@media only screen and (min-width: 600px) and (max-width: 991px) {
  li {
    padding-left: 2.5em;
  }
  .activ {
    background-color: #1f84fc;
    color: #eee !important;
    padding-left: 10px;
    border-radius: 19px;
  }
  #why {
    padding: 7vh 7em;
  }
}

#Membership {
  margin-bottom: 80px;
}

.priceplanbg {
  background: #000;
  color: #fff;
  padding: 20px;
}

.priceplanrecomended {
  background: #05aef9;
  color: #fff;
  padding: 20px;
}

.headingprice {
  font-size: 50px;
  color: #05aef9;
}

.subheadingprice {
  font-size: 20px;
  color: #05aef9;
  font-weight: 300;
}

.classprice,
.reducedprice {
  color: #8d8b8b;
  font-size: 30px;
  font-weight: 300;
}

.reducedprice {
  text-decoration: line-through;
}

.recomended {
  border: 2px solid #05aef9;
}

.margins {
  margin: auto auto auto 0;
  padding-top: 60px;
}

.benifitscard {
  box-shadow: 0 0 16px rgba(0, 0, 0, 0.18);
  border-radius: 10px;
  background: #fff;
  margin-top: 20px;
  position: relative;
  z-index: 100;
}

.benifitscard:hover {
  box-shadow: 0 0 24px rgba(0, 0, 0, 0.3);
  -webkit-transform: scale(1.05, 1.05);
          transform: scale(1.05, 1.05);
  z-index: 1000;
  transition: 0.3s;
}

.startcard {
  margin: 2em;
}

@media only screen {
  .banner {
    top: 0vw;
    width: 100vw;
  }

  .banner-header {
    padding: 5px 5px;
    background: #555;
    font-size: 17px;
    color: white;
    text-align: center;
  }

  .banner-container {
    padding: 5px;
    background-color: #ccc;
    color: black;
  }
  .banner-signup {
    padding: 5px 5px;
    font-size: 17px;
    color: #f4b429;
    text-align: center;
    text-decoration: underline;
  }
  .banner-signup:hover {
    padding: 5px 5px;
    font-size: 20px;
    color: #f4b429;
    text-align: center;
    text-decoration: underline;
  }
  .banner-closebtn {
    position: absolute;
    top: -4px;
    right: 5px;
    color: white;
    font-size: 30px;
    cursor: pointer;
  }

  .banner-closebtn:hover {
    color: darkgrey;
  }
}

@media only screen and (max-width: 1024px) {
  .banner-header {
    font-size: 20px;
  }
  .banner-signup {
    font-size: 20px;
  }
}

@media only screen and (max-width: 350px) {
  .banner-header {
    font-size: 15px;
  }
  .banner-signup {
    font-size: 15px;
  }
}

#howitworks,
#join {
  padding: 0;
}

.settings-div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 90%;
  margin: 10px auto;
  text-align: start;
  flex-wrap: wrap;
}
/* .settings-div2{
	display: flex;
	width: 44%;
	margin: 10px auto;
  } */
.settings-div3 {
  display: flex;
  flex-direction: row;
  /* flex-wrap: wrap; */
  width: 70%;
  margin: 0 auto;
  text-align: start;
  justify-content: space-between;
}
.plll {
  width: 300px;
  left: 0 !important;
  position: relative;
}
.pos {
  position: relative;
  left: 0 !important;
}
.iti__flag-container {
  position: relative;
  left: -30px !important;
}
@media (max-width: 768px) {
  .settings-div,
  .settings-div3 {
    width: 95%;
  }
}
@media (max-width: 500px) {
  .settings-div,
  .settings-div3 {
    /* width: 95%; */
    justify-content: space-around;
    flex-direction: column;
  }
  .plll {
    width: 200px;
  }
}

.ui.selection.dropdown {
  left: 0 !important;
}

@media (max-width: 700px) {
  .banner-header,
  .banner-signup {
    font-size: small;
  }
}
.contain-hero-div {
  position: relative;
}
.hero-videos {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
}

.hero-video2 {
  position: absolute;
  right: -5rem;
  top: -100px;
  padding: 20px;
  background: rgb(250, 244, 255);
  border: transparent;
}
@media (max-width: 768px) {
  .hero-videos {
    height: -webkit-max-content;
    height: max-content;
    flex-direction: column;
    justify-content: space-evenly;
  }
  .contain-hero-div {
    position: static;
  }
  .hero-video2 {
    position: relative;
    top: 25px;
    right: 5rem;
    height: 260px;
    width: 480px;
  }
}

@media (max-width: 720px) {
  .hero-video2 {
    width: 90vw;
    margin-left: 0;
    right: 0;
  }
  .headinghero {
    font-size: 1.2rem;
  }
}
.logo-style {
  height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.logo-style > img {
  height: 75%;
  width: auto;
}
.logo-name {
  margin-left: 5px;
  font-size: 25px;
}
.new-signup {
  border-radius: 5px !important;
  background-color: #28a745 !important;
  border-color: #28a745 !important;
  border: transparent !important;
  padding: 10px !important;
  font-weight: bold !important;
  transition: background-color 0.3s ease-in-out !important;
  cursor: pointer;
}
.new-signup:hover {
  color: white !important;
  background-color: skyblue !important;
  border: transparent !important;
}
.login-s {
  margin-left: 20px;
  background-color: #007bff !important;
  border-color: #007bff !important;
}
#footer {
  /* min-height: 20rem; */
}
.footer-style {
  display: flex;
  width: 95%;
  margin: 0 auto;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  color: #3c506f;
}
.footer-div1,
.footer-div2,
.footer-div3 {
  width: 33.3%;
}

@media (max-width: 768px) {
  .footer-div1,
  .footer-div2 {
    width: 50%;
  }
  .footer-div3 {
    width: 100%;
  }
}
@media (max-width: 480px) {
  .footer-div1 {
    width: 90%;
  }
  .footer-div2 {
    margin-top: 10px;
    width: 90%;
  }
}
.footer-div1 {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.footer-div1-div {
  margin-top: 5px;
  text-align: left;
  font-size: 1.2rem;
}
.footer-div2 {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}
.footer-div2-div {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.footer-div2-div a {
  color: #3c506f !important;
  transition: border-bottom 0.3s ease-in-out;
}
.footer-div2-div a:hover {
  border-bottom: 2px solid skyblue;
}

@media (max-width: 480px) {
  .visibleNot {
    display: none;
  }
  .footer-div2 {
    width: 70%;
  }
}
.footer-div3 > span {
  font-size: 1.4rem;
  color: #3c506f;
}
.navbar-nav {
  font-weight: bold;
}
.headinghero {
  color: #3c506f;
  font-weight: bold;
}

.footer-wrapper {
  padding-left: min(13vw, 30rem);
}
.footer-container {
  display: grid;
  grid-template-columns: 1.4fr 0.6fr 0.6fr;
  grid-template-rows: 0.6fr 1.4fr;
  grid-auto-columns: 1fr;
  grid-gap: 0px 0px;
  gap: 0px 0px;
  grid-auto-flow: row;
  grid-template-areas:
    "top a side"
    "bottom  a side";
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}
.top {
  grid-area: top;
  margin-top: 2em;
}
.footer-wrapper {
  padding-left: min(13vw, 30rem);
}
.footer-btn {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-105%, -50%);
          transform: translate(-105%, -50%);
  border-radius: 30px;
}

.bottom {
  grid-area: bottom;
  display: flex;
}
.side {
  display: block;
  grid-area: side;
}
.footer-container a {
  font-size: 1rem;
  padding: 0.5em;
  color: rgba(0, 0, 0, 0.18);
}

@media (max-width: 480px) {
  .heading-nav {
    display: flex;
    flex-direction: row;
    width: 100%;
  }
  .navbar-brand {
    width: 68%;
  }
}
@media (max-width: 800px) {
  .footer-container {
    display: grid;
    grid-template-columns: 1.4fr;
    grid-template-rows: 0.6fr 1.4fr;
    grid-gap: 0px 0px;
    gap: 0px 0px;
    grid-auto-flow: row;
    grid-template-areas:
      "top"
      "bottom";
    width: 80%;
    height: 100%;
    margin: 0 auto;
    padding: 1em;
  }
  .footer-wrapper {
    padding-left: 0;
  }
  .side {
    display: none;
  }
}

.modal-css {
  height: 400px;
}

.custom-card {
  background-color: rgba(255, 255, 255, 0.8);
  font-weight: 600;
  border-radius: 20px;
  border: 1px solid #eee;
  position: absolute;
  top: 60%;
  left: 35%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.custom-card > .card-body {
  max-width: 50ch;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.card-title {
  font-size: 2.35rem;
}
.card-text {
  font-size: 0.98rem;
  color: hsl(0, 0, 60%);
  max-width: 45ch;
}
.hero-btn {
  padding: 5px 8px;
}

.hero-sidebar {
  position: absolute;
  width: 3em;
  background-color: #00b1ff;
  bottom: 10%;
  left: 0;
}

.hero-sidebar ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.hero-sidebar li {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.hero-sidebar li a {
  color: #fff !important;
  text-decoration: none !important;
}

.hero-sidebar li a i {
  font-size: 20px;
  margin: 5px 0;
}

.process-con {
  display: flex;
  justify-content: center;
}
.process-con img {
  max-width: min(60vh, 35rem);
}

@media only screen and (max-width: 767px) {
  .custom-card {
    width: 70%;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    max-width: -webkit-fit-content;
    max-width: -moz-fit-content;
    max-width: fit-content;
  }
  .custom-card > .card-body {
    max-width: -webkit-max-content;
    max-width: max-content;
  }
  .custom-card .card-title {
    font-size: 1.5rem;
  }
  .custom-card .card-text {
    display: none;
  }
}

.Weight-Div{
    display:flex;
    flex-direction:column;
}

.Weight-Main{
    display:flex;
    flex-direction:row;
    justify-content: space-evenly;
    align-items: center;
    min-height:200px;
}
.Weight-Main div{
    width:50%;
}

.Weight-Main-Div > div{
    width:90%;
    margin:0 auto;
    display:flex;
    flex-direction: column;
    justify-content: space-between;
}
.buttcss{
    font-size: 1.2rem;
    width:70%;
}
.buttcss2{
    font-size: 0.9rem;
    width:70%;
}
img{
    width:80%;
    height:auto;
}
.Decsribe-Main{
    padding:20px 0;
    background:rgba(211, 211, 211, 0.2);
    display:flex;
    flex-direction: column;
    align-items: center;
    margin-top:20px;
}

ul{
    color: dodgerblue;
}
li{
    margin:5px 0;
    padding-left: 0;
}
li span{
    color:black;
}
.ImageDescribe-Div{
    display:flex;
    flex-direction: column;
    align-items: center;
    padding: 20px 0;
}
.ImageDescribe-Main{
    display: flex;
    flex-direction:row;
    width:70%;
    margin:0 auto;
    justify-content: space-evenly;
}
.ImageDescribe-Main-Div{
    display: flex;
    flex-direction: column;
    align-items: center;
    width:100%;
    background:rgba(211, 211, 211, 0.2);
    margin:0 10px;
    justify-content: space-evenly;
    padding-bottom:10px;
}
.ImageDescribe-Main-Div > div{
    width:90%;
}
.ImageDescribe-Main-Div > div > img{
    width:100%;
    min-width: 100px;
    height:auto;
}
.PriceDetail-Main{
    width:30%;
    margin:0 auto;
    padding:20px;
    display:flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    background: rgba(211, 211, 211, 0.2);
    text-align: center;
}
.PriceDetail-Main-Div{
    width:100px;
    height:100px;
    border: 5px solid black;
    border-bottom-color: dodgerblue;
    color:dodgerblue;
    border-radius: 50%;
    
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.Help-Main{
    margin-top:20px;
    padding:20px 0;
    background: rgba(211, 211, 211, 0.2);
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
}
.Help-Form{
    width:50%;
}
.Help-Form input{
    width:100%;
    border-radius: 20px 20px;
    padding: 10px;
    background:lightcyan;
    border-top-color:lightblue;
    color:silver;
    border-color: dodgerblue !important;
}
.Logo-Main{
    margin-top:10px;
    display:flex;
    flex-direction:row;
    justify-content: center;
    align-items: center;
    padding:10px;
}
.Logo-Main > div{
    width:30px;
    height:30px;
    border-radius: 50%;
    background:rgba(211,211,211,0.2);
    margin-right:10px;
}
.Logo-Main > div > img {
    width:70%;
    position: relative;
    left:5px;
    top:4px;
    height:auto;
    
}
.Contact-Main{
    width:70%;
    margin: 0 auto;
    margin-top: 10px;
    padding:10px 0;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}
.Contact-Main-Div{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.Contact-Main-Div div,
.Contact-Main-Div div>img {
    width:50%;
    height:auto;
    margin:0 auto;
    
}
.Contact-Main-Div div>img{
    position: relative;
    left:2.5vw;
}
@media(max-width:768px){
    .buttcss,.buttcss2{
        width:90%;
        font-size: 0.8rem;
    }
    h2{
        font-size:3vw !important;
    }
}
@media(max-width:580px){
    .PriceDetail-Main{
        width:90%;
    }
    .buttcss,.buttcss2{
        width:100%;
        font-size: 0.7em;
    }
    .Help-Form{
        width:80%;
    }
}
.membership-plan-container {
  /* width: 80vw; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 10px auto 30px auto;
}

.membershipPageHeading {
  text-align: center;
  font-size: 3rem;
  padding-top: 30px;
}

.planSelectors {
  width: 60vw;
  margin: auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.singleSelector {
  margin: 20px 8px;
}

.sessionSelection {
  border: 1.6px solid #17a3fd;
  width: 600px;
  padding: 20px 30px;
  /* border-radius: 50px; */
}

#dialogBox {
  border-radius: 10px !important;
}

.dialogActionButtons {
  display: flex;
  justify-content: space-between !important;
}

.dialogActionButton {
  height: 50px !important;
  width: 180px !important;
  background-color: #17a3fd !important;
  color: white !important;
  border-radius: 5px !important;
  border: none;
}

.plansDisplay {
  /* width: 60vw; */
  margin: auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.singleSelector__button {
  width: 10rem !important;
  height: 55px !important;
  border-radius: 10px !important;
  background-color: #c9c9c9 !important;
  border: none !important;
  color: #808080 !important;
  font-size: 1rem;
  padding: 0 0.23em !important;
}

.singleSelector__button.active {
  background-color: #17a3fd !important;
  color: white !important;
}

.singleSelector__button:hover {
  background-color: #17a3fd !important;
  color: white !important;
}

.confirmPackageContainer {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.continueToPayment {
  background-color: #17a3fd !important;
  color: white !important;
  border: none !important;
  width: 200px;
  height: 50px;
  margin: auto;
  margin-bottom: 80px;
  border-radius: 5px !important;
}
.tab__containerWrapper {
  display: inline;
  display: initial;
  max-width: 80%;
}
.tab__container,
.tab__containerMobile {
  display: flex;
  grid-gap: 1rem;
  gap: 1rem;
  position: relative;
  margin: 0 auto;
  margin-top: 2rem;
  justify-content: center;
  max-width: 90%;
}
.tab__container {
  display: none;
}
.membership__leftIcon,
.membership__rightIcon {
  cursor: pointer;
  border: 1px solid rgb(231, 231, 231);
  border-radius: 50%;
  background-color: rgb(255, 255, 251);
  color: rgb(138, 138, 138);
}
.tab__containerWrapper {
  display: flex;
  margin: 0 auto;
  justify-content: space-evenly;
  align-items: center;
}
@media screen and (min-width: 900px) {
  .tab__container {
    display: flex;
    flex-wrap: wrap;
  }
  .membership__leftIcon,
  .membership__rightIcon {
    display: none !important;
  }
  .tab__containerMobile {
    display: none;
  }
}

.tab__container::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  -webkit-transform: translate(-50%, -20px);
          transform: translate(-50%, -20px);
  width: calc(105%);
  height: 2px;
  background: #1f84fc;
}
.tab__wrapper {
  width: max(200px, 5vw);
  padding: 1rem;
  position: relative;
  margin-bottom: 20px;
  text-align: center;
  background-color: #e2e2e2;
  color: #064065;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.tab__wrapper.active {
  background-color: #1f84fc;
  color: #fff;
}
.tab__wrapper.active::after {
  content: "";
  position: absolute;
  left: 50%;
  -webkit-transform: translate(-50%, 15px);
          transform: translate(-50%, 15px);
  bottom: 0;
  width: 0;
  height: 0;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  border-top: 15px solid #1f84fc;
}

.tab__wrapper p {
  font-size: 1.5rem;
  white-space: nowrap;
}

.membership__point {
  display: flex;
  margin: 0.2rem;
  justify-content: flex-start;
  align-items: center;
}
.membership__point p {
  font-size: clamp(0.5rem, 6vw, 1.875rem);
}

.membership__point img {
  width: 2.5rem;
  height: 1.875rem;
}

.membership__card {
  width: 278px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding-top: 1rem;
  box-sizing: border-box;
  position: relative;
}

.membership__card h1 {
  color: #06b100;
  font-weight: 900;
  font-size: 4.125rem;
  margin-bottom: 0;
  padding-bottom: 0;
  display: inline-block;
}

.membership__card h2 {
  margin-top: 0;
  margin-bottom: 5px;
  padding-top: 0;
  font-size: 1.125rem !important;
}

.membership__btn {
  text-transform: uppercase;
  background: #064065;
  color: #fff;
  width: 100%;
  padding: 0.9em 0;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}
.membership__btn:hover {
  background: #1f84fc;
}
.membership__btn img {
  height: 20px;
  width: 20px;
  margin-left: 5px;
}

.membership__linethrough {
  position: relative;
  color: hsl(0, 0%, 30%);
}
.membership__linethrough::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background: #1f84fc;
  -webkit-transform: translateY(10px) rotateZ(30deg);
          transform: translateY(10px) rotateZ(30deg);
}
.btn-default {
  background: #1f84fc;
}
.membership__cardContainer {
  width: 80%;
  margin: 1rem auto;
  display: flex;
  grid-gap: 2rem;
  gap: 2rem;
  justify-content: center;
  flex-wrap: wrap;
}

.fitness__checkbox {
  position: relative;
  min-width: 80px;
  height: 40px;
  margin: 10px;
  outline: none;
  background: #f1f1f1;
  -webkit-appearance: none;
  cursor: pointer;
  border-radius: 20px;
  border: 3px solid #1f84fc;
  transition: 0.5s;
  margin: 3em 1em;
}

.fitness__checkbox:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 35px;
  height: 35px;
  background: #1f84fc;
  border-radius: 20px;
  box-shadow: 0 0 0 1px #f1f1f1;
  -webkit-transform: scale(0.98, 0.96);
          transform: scale(0.98, 0.96);
  transition: 0.5s;
}
.fitness__checkbox:checked::before {
  /* left: 40px; */
  left: 100%;
  -webkit-transform: translateX(-100%);
          transform: translateX(-100%);
}

.fitness__checkboxCon {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 80%;
  margin: 0 auto;
}

.fitness__checkboxCon h3 {
  color: #064065;
  margin: 0;
}

.checkoutContainerBox {
  width: 60vw;
  height: 350px;
  display: flex;
  border: 1px solid black;
  margin: 150px auto 50px auto;
  padding: 15px 30px;
  flex-direction: column;
}

.headerTextDiv__heading {
  font-size: 1.7rem;
}

.bodyTable {
  height: 40px;
  display: flex;
  flex-direction: row;
  width: 100%;
  /* font-weight: bold;
  font-size: 1.2rem; */
}

.bodyTable {
  font-weight: bold;
  font-size: 1.2rem;
}

.bodyLeft {
  width: 20%;
}
.bodyCenter {
  width: 60%;
}
.bodyRight {
  width: 20%;
}

.bodyTableHead {
  margin-top: 2%;
}
.bodyTableContent {
  margin-top: 5%;
}

.bodyTablePrice {
  margin-top: 8%;
}

.Totalprice {
  border-top: 1px solid black;
}

.nf {
  margin: auto;
  width: 10%;
  padding: 10px;
  font-size: 100px;
}

.nft {
  margin-left: 35%;
}

.nfm {
  margin-left: 37%;
}

.nfb {
  margin-left: 47%;
  margin-top: 10%;
}

.modal-backdrop {
  z-index: 2000;
}

.modal-dialog {
  z-index: 2010;
}

.ui.modal > .close {
  top: 1rem;
  right: 0.5rem;
  color: #333;
}

.learn {
  border: 1px solid #07f;
  border-radius: 20px;
  margin-top: 1em;
  padding: 0.3em 1em;
  color: #07f;
  transition-duration: 0.3s;
}

.learn:hover {
  background-color: violet;
  border: 1px solid violet;
  color: #fff;
}

.offer-card {
  width: 20rem;
  border: 1px solid #eee;
  border-radius: 30px;
  overflow: hidden;
  margin: 1em;
  position: relative;
}
.offer-card img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  padding: 0 !important;
  margin: 0 !important;
}
.offer-card h1 {
  background-color: rgba(238, 238, 238, 0.9);
  padding: 5px 0;
  font-size: 1.5rem;
  position: absolute;
  bottom: 0;
  left: 0;
  margin: 0 auto;
  width: 100%;
}
#services,
#testimonials {
  width: min(90vw, 80rem);
  margin: 0 auto;
}
.ui.fullscreen.modal {
  width: 95% !important;
  left: 2.5% !important;
  margin: 1em auto;
  height: 100%;
  overflow: scroll;
}

.work-container {
  width: min(90vw, 70rem);
  margin: 2em auto;
  border-radius: 20px;
  box-shadow: 0 1.5px 10px rgba(0, 0, 0, 0.07), 0 1px 10px rgba(0, 0, 0, 0.14);
  padding: 3em 0;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
}
.work-container > * {
  flex-basis: 1;
}
.work-container div {
  margin: 10px 0;
  max-width: 15rem;
}

.card-number {
  color: #5da0e7;
  font-size: 5.5rem;
}

.process-card-title {
  font-size: 3.5em;
  margin-top: -20px;
  max-width: 45ch;
}

.process-con {
  display: flex;
  flex-direction: row;
  margin-top: 3em;
}
.process-con > * + * {
  margin-left: 5em;
}
.process-con p {
  color: #193144;
  max-width: 30ch;
  font-size: 1.5em;
  color: "#0b0818";
}
.process-con > * {
  flex-basis: 1;
}

.text-r {
  text-align: right;
}

.page_404{ padding:40px 0; background:#fff; font-family: 'Arvo', serif;
}

.page_404  img{ width:100%;}

.four_zero_four_bg{
 
 background-image: url(https://cdn.dribbble.com/users/285475/screenshots/2083086/dribbble_1.gif);
    height: 400px;
    background-position: center;
 }
 
 
 .four_zero_four_bg h1{
 font-size:80px;
 }
 
  .four_zero_four_bg h3{
			 font-size:80px;
			 }
			 
			 .link_404{			 
	color: #fff!important;
    padding: 10px 20px;
    background: #1f84fc;
    margin: 20px 0;
    display: inline-block;}
	.contant_box_404{ margin-top:-50px;}

@media only screen and (max-width: 767px) {
  .ui.stackable.cards .card:first-child {
    margin-top: 2em !important;
  }
  .ui.stackable.cards > .card {
    display: block !important;
    height: auto !important;
    margin: 1em;
    padding: 1em !important;
    width: calc(100% - 2em) !important;
  }
}

@media only screen and (max-width: 750px) {
  .process-con {
    display: flex;
    flex-direction: column;
    text-align: left !important;
  }
  .process-con > * + * {
    margin-left: 0;
  }
  .text-r {
    text-align: left !important;
  }
}

